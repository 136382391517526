import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const ResearchOverviewDiv = styled.div`
  margin: 40px
`


export default function ShowcaseResearchListContainer(props) {
    const {researchList} = props

    const [rList, setRList] = useState()

    useEffect(() => {
        setRList(researchList.reverse())
    }, [researchList])


    return(
        <div className="ShowcaseResearchListContainer">
            {rList &&
            (<section id="highlights" className="wrapper style3">
                <div className="title">Research</div>
                <div className="container">
                    {
                        rList.map(publication => (
                            <ResearchOverviewDiv key={publication.contentID}>
                                <div className="row aln-center">
                                    <section className="highlight">
                                        <div className="ql-editor" dangerouslySetInnerHTML={{__html: publication.contentHTML}} />
                                    </section>
                                </div>
                            </ResearchOverviewDiv>
                        ))
                    }
                </div>
            </section>)}
        </div>

    )
}