import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {
    getAllContentClass,
    logout,
    postNewContentClass,
    removeContentClass
} from "../../../utils/api";
import {useNavigate} from "react-router";

export default function AdminNavBar() {
    const navigate = useNavigate();

    const userID = localStorage.getItem('userID')

    const [newContentContainer, setNewcontentContainer] = useState(false)
    const [removeContentContainer, setRemovecontentContainer] = useState(false)
    const [contentClassList, setContentClassList] = useState([])

    const [newClassName, setNewClassName] = useState("")
    const [newDisplayName, setNewDisplayName] = useState("")

    const [removeClassName, setRemoveClassName] = useState("")

    useState(() => {
        getAllContentClass().then(res => {
            setContentClassList(res.data)
        })
    })

    function logoutAdmin() {
        logout(userID).then(res => {
            navigate("/login")
        })
    }

    function addModel() {
        setNewcontentContainer(true)
    }

    function deleteModel() {
        setRemovecontentContainer(true)
    }

    function addContentClass() {
        postNewContentClass(newClassName, newDisplayName, false).then(res => {
            window.location.reload()
        })
    }

    function deleteContentClass() {
        removeContentClass(removeClassName).then(res => {
            window.location.reload()
        })
    }

    const handleCloseNewClass = () => setNewcontentContainer(false);
    const handleCloseRemoveClass = () => setRemovecontentContainer(false);

    function handleModel0({target: {value}}) {
        setNewClassName(value)
    }

    function handleModel1({target: {value}}) {
        setNewDisplayName(value)
    }

    function handleModel2({target: {value}}) {
        setRemoveClassName(value)
    }

    return (
        <div className="AdminNavBar">
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="/admin">Website Control Panel</Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link href="/admin">Home</Nav.Link>
                        {contentClassList.map(contentClass =>
                            (contentClass.displayName !== "research" &&
                                <Nav.Link href={`/admin/content/${contentClass.contentClass}`}>{contentClass.displayName}</Nav.Link>
                            )
                        )
                        }
                        <NavDropdown title="Research" id="basic-nav-dropdown">
                            <NavDropdown.Item  href="/admin/content/research_overview">Overview</NavDropdown.Item>
                            <NavDropdown.Item  href="/admin/content/research_detail">Detail</NavDropdown.Item>
                            <NavDropdown.Item  href="/admin/content/research_file">File</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={addModel}>+</Nav.Link>
                        <Nav.Link onClick={deleteModel}>-</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={logoutAdmin}>Logout</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <Modal show={newContentContainer} onHide={handleCloseNewClass}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new content class</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Content Class Name</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Class Name"
                                          value={newClassName}
                                          onChange={handleModel0}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Displayed Name</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Display Name on Navbar"
                                          value={newDisplayName}
                                          onChange={handleModel1}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewClass}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={addContentClass}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={removeContentContainer} onHide={handleCloseRemoveClass}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove content class</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Content Class Name</Form.Label>
                            <Form.Control type="text"
                                          placeholder="Enter Class Name"
                                          value={removeClassName}
                                          onChange={handleModel2}
                            />
                            <Form.Text className="text-danger">
                                Be careful! Deleting the content class will remove all its contents.
                            </Form.Text>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseRemoveClass}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={deleteContentClass}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}