import React, {useState, useEffect} from 'react';
import {Link} from 'react-scroll'


export default function ShowcaseHeader(props) {

    const {contentList} = props

    const [headerTitle, setHeaderTitle] = useState('');

    useEffect(() =>{
        if (contentList.length !== 0) {
            setHeaderTitle(contentList.find(o => o.contentID === "homepage_header_title"))
        }
    }, [contentList])


    return (
        <div className="ShowcaseNavbar">
            {headerTitle &&
            (<section id="header" className="wrapper">

                <div id="logo">
                    <h1><a href="/" className="ql-editor" dangerouslySetInnerHTML={{__html:headerTitle.contentHTML}}/></h1>
                </div>


                <nav id="nav">
                    <ul>
                        <li className="current"><a href="/">Home</a></li>
                        <li><a href="/research">Research</a></li>
                        <li><a href="/teaching">Teaching</a></li>
                        <li><a href="/alumni">Students</a></li>
                    </ul>
                </nav>

            </section>)
            }
        </div>
    )
}