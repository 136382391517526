import React, {useState, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default function ShowcaseTeachingSub(props) {

    const {teachingContentList} = props

    const [teachingList, setTeachingList] = useState([]);

    useEffect(() =>{
        if (teachingContentList.length !== 0) {
            setTeachingList(teachingContentList)
        }
    }, [teachingContentList])
    return (
        <div className="ShowcaseTeachingSub">
            {teachingList &&
            (<section id="highlights" className="wrapper style3">
                <div className="title">Teaching</div>
                <div className="container">
                    {
                        teachingList.map(teachingEntry => (
                            <div className="row aln-center">
                                <section className="highlight">
                                    <div className="ql-editor" dangerouslySetInnerHTML={{__html: teachingEntry.contentHTML}} />
                                </section>
                            </div>
                        ))
                    }
                </div>
            </section>)}
        </div>
    )
}