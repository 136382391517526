import React, {useState, useEffect} from 'react';
import './subComponents/quillContent.css';
import "./assets/css/main.css";
import "./assets/css/modified.css";
import {getContentByClass, getContentClass} from "../../utils/api";
import ShowcaseHeader from "./subComponents/ShowcaseHeader";
import ShowcaseFooter from "./subComponents/ShowcaseFooter";
import ShowcaseTeachingSub from "./subComponents/ShowcaseTeachingSub";



export default function ShowcaseTeaching() {

    const [contentList, setContentList] = useState([])
    const [teachingContentList, setTeachingContentList] = useState([])
    const [displayTeaching, setDisplayTeaching] = useState(false)

    useState(() => {
        getContentByClass("teaching").then(res => {
            setTeachingContentList(res.data)
        })
        getContentByClass("homePage").then(res => {
            setContentList(res.data)
        })
        getContentClass('teaching').then(res => {
            setDisplayTeaching(res.data.displaySwitch)
        })
    })


    return (
        <div className="ShowcaseHomepage">
            {(contentList) &&
            (
                <div className="page-wrapper">
                    <ShowcaseHeader contentList={contentList}/>

                    {displayTeaching && (
                        <ShowcaseTeachingSub teachingContentList={teachingContentList}/>
                    )
                    }
                    <ShowcaseFooter contentList={contentList}/>
                </div>
            )}
        </div>
    )
}