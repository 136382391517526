import axios from 'axios'

const WebsiteBackend = axios.create({
    timeout: 50000,
    // baseURL: 'http://localhost:8000/api'
    baseURL: 'https://www.zhiyuquan.net/api'
})

// content class apis
export function getAllContentClass() {
    return WebsiteBackend({
        method: 'GET',
        url: `/allContentClass`,
    })
}

export function getContentClass(className) {
    return WebsiteBackend({
        method: 'GET',
        url: `/contentClass/${className}`,
    })
}

export function postNewContentClass(className, displayName, displaySwitch) {
    return WebsiteBackend({
        method: 'POST',
        url: `/contentClass/${className}`,
        data: {
            contentClass: className,
            displayName: displayName,
            displaySwitch: displaySwitch,
        }
    })
}

export function removeContentClass(className) {
    return WebsiteBackend({
        method: 'DELETE',
        url: `/contentClass/${className}`,
    })
}




// content apis
export function getAllContentAscID() {
    return WebsiteBackend({
        method: 'GET',
        url: `/content/ascID`,
        // headers: {
        //     'Access-Control-Allow-Origin': '*'
        // }
    })
}

export function getContentByClass(contentClass) {
    return WebsiteBackend({
        method: "GET",
        url: `/content/class/${contentClass}`,
    })
}

export function getResearchContent(researchID) {
    return WebsiteBackend({
        method: "GET",
        url: `/content/research/${researchID}`,
    })
}

export function getContentByID(contentID) {
    return WebsiteBackend({
        method: 'GET',
        url: `/content/id/${contentID}`
    })
}

export function insertNewContent(contentID, contentTitle, newContentHTML, newContentDelta, currentClass) {
    return WebsiteBackend({
        method: 'POST',
        url: `/content/post`,
        data: {
            contentID: contentID,
            contentClass: currentClass,
            contentTitle: contentTitle,
            contentHTML: newContentHTML,
            contentDelta: newContentDelta
        }
    })
}

export function upsertContent(oldContentID, contentID, contentTitle, newContentHTML, newContentDelta, currentClass) {
    return WebsiteBackend({
        method: 'PUT',
        url: `/content/put/${oldContentID}`,
        data: {
            contentID: contentID,
            contentClass: currentClass,
            contentTitle: contentTitle,
            contentHTML: newContentHTML,
            contentDelta: newContentDelta,
        }
    })
}

export function deleteContentByID(contentID, contentClass) {
    return WebsiteBackend({
        method: 'DELETE',
        url: `/content/delete/${contentID}/${contentClass}`
    })
}

export function getPDFFile(content_id) {
    return WebsiteBackend({
        method: 'GET',
        url: `/content/file/${content_id}`,
    })
}


export function getPDFcontent(content_id) {
    return WebsiteBackend({
        method: 'GET',
        url: `/content/pdf_content/${content_id}`,
    })
}



export function uploadFile(content_id, file, content_title) {
    return WebsiteBackend({
        method: 'POST',
        url: `/content/file/${content_id}`,
        data: {
            file: file,
            title: content_title,
        }
    })
}


// login apis
export function adminLogin(userID, password) {
    return WebsiteBackend({
        method: 'POST',
        url: `/login`,
        data: {
            userID: userID,
            password: password,
        }
    })
}

export function adminTokenCheck(userID, token) {
    return WebsiteBackend({
        method: 'POST',
        url: `/tokenCheck`,
        data: {
            userID: userID,
            token: token,
        }
    })
}

export function logout(userID) {
    return WebsiteBackend({
        method: 'POST',
        url: `/logout`,
        data: {
            userID: userID,
        }
    })
}


// research paper view counts and file upload
export function updateViewCount(research_paper_id) {
    return WebsiteBackend({
        method: 'POST',
        url: `/research_paper/view/${research_paper_id}`,
    })
}


export function updateDownloadCount(research_paper_id) {
    return WebsiteBackend({
        method: 'POST',
        url: `/research_paper/download/${research_paper_id}`,
    })
}


export function getResearchPaperInfo(research_paper_id) {
    return WebsiteBackend({
        method: 'GET',
        url: `/research_paper/${research_paper_id}`,
    })
}


export function deleteResearchPaper(research_paper_id) {
    return WebsiteBackend({
        method: 'DELETE',
        url: `/research_paper/${research_paper_id}`,
    })
}

export function uploadPaper(research_paper_id, pdfFile, title, citationList) {
    return WebsiteBackend({
        method: 'POST',
        url: `/research_paper/file/${research_paper_id}`,
        data: {
            file: pdfFile,
            title: title,
            citationList: citationList
        }
    })
}

export function getFile(research_paper_id) {
    return WebsiteBackend({
        method: 'GET',
        url: `/research_paper/file/${research_paper_id}`,
    })
}



export function getCitation(research_paper_id) {
    return WebsiteBackend({
        method: 'GET',
        url: `/research_paper/citation/${research_paper_id}`,
    })
}


// homepage message APIs
export function getAllMessages() {
    return WebsiteBackend({
        method: 'GET',
        url: `/message`,
    })
}

export function putMessage(name, email, message) {
    return WebsiteBackend({
        method: 'PUT',
        url: `/message`,
        data: {
            name: name,
            email: email,
            message: message
        }
    })
}

export function deleteMessage(messageID) {
    return WebsiteBackend({
        method: 'DELETE',
        url: `/message/${messageID}`,
    })
}