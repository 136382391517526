import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from "react-router";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styled from "styled-components";
import {adminLogin} from "../utils/api";
import md5 from "md5";

const LoginContainer = styled(Container)`
  margin-top: 100px;
`

export default function LoginPage() {
    const navigate = useNavigate();
    const md5 = require("md5")

    const [userID, setUserID] = useState("")
    const [password, setPassWord] = useState("")
    const [pwIncorrect, setPWIncorrect] = useState(false)

    function onInputUserID({target: {value}}) {
        setUserID(value)
    }

    function onInputPassword({target: {value}}) {
        setPassWord(value)
    }

    function signIn() {
        adminLogin(userID, md5(password)).then(res => {
            if (res.data.result === "1") {
                console.log("lets go")
                localStorage.setItem("userID", userID)
                localStorage.setItem("loginToken", res.data.token)
                setPWIncorrect(false)
                navigate("/admin")
            } else {
                setPWIncorrect(true)
            }
        })
    }

    function handleKeyPress(target) {
        if (target.charCode === 13) {
            target.preventDefault()
            signIn()
        }
    }

    return (
        <div className="LoginPage">
            <LoginContainer>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>User ID</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Enter User ID"
                                      value={userID}
                                      onChange={onInputUserID}
                                      onKeyPress={handleKeyPress}
                        />
                        {
                            pwIncorrect &&
                            <Form.Text className="text-danger">
                                ID or Password Incorrect
                            </Form.Text>
                        }

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password"
                                      placeholder="Password"
                                      value={password}
                                      onChange={onInputPassword}
                                      onKeyPress={handleKeyPress}
                        />
                    </Form.Group>
                    <Button variant="primary" onClick={signIn}>
                        Sign In
                    </Button>
                </Form>
            </LoginContainer>
        </div>
    )
}