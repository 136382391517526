import React, {useState, useEffect} from 'react';
import './subComponents/quillContent.css';
import "./assets/css/main.css";
import "./assets/css/modified.css";
import {getContentByClass, getContentClass} from "../../utils/api";
import ShowcaseHeader from "./subComponents/ShowcaseHeader";
import ShowcaseBiography from "./subComponents/ShowcaseBiography";
import ShowcaseExperience from "./subComponents/ShowcaseExperience";
import ShowcasePublications from "./subComponents/ShowcasePublications";
import ShowcaseFooter from "./subComponents/ShowcaseFooter";
import ShowcaseTalks from "./subComponents/ShowcaseTalks";



export default function ShowcaseHomepage() {

    const [contentList, setContentList] = useState([])
    const [talkList, setTalkList] = useState([])
    const [displayHome, setDisplayHome] = useState(false)

    useState(() => {
        getContentByClass('homePage').then(res => {
            setContentList(res.data)
        })
        getContentByClass("talk_content").then(res => {
            setTalkList(res.data)
        })
        getContentClass("homePage").then(res => {
            setDisplayHome(res.data.displaySwitch)
        })
    })


    return (
        <div className="ShowcaseHomepage">
            {(contentList) &&
                (
                    <div className="page-wrapper">
                        <ShowcaseHeader contentList={contentList}/>

                        {displayHome &&
                        (<div>
                            <ShowcaseBiography contentList={contentList}/>

                            <ShowcaseExperience contentList={contentList}/>

                            <ShowcaseTalks contentList={talkList}/>
                        </div>)

                        }

                        <ShowcaseFooter contentList={contentList}/>
                    </div>
            )}
        </div>
    )
}