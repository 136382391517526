import React, {useState, useEffect} from 'react';
import {Card} from "react-bootstrap";
import {AlumniCard} from "./CustomStyles";
import  './quillContent.css';


export default function ShowcaseAlumniListContainer(props) {
    const {alumniList} = props

    return(
        <div className="ShowcaseAlumniListContainer">
            {alumniList &&
            (<section id="highlights" className="wrapper style2">
                <div className="title">PhD</div>
                <div className="container">
                    <div className="row aln-center">
                        {
                            alumniList.map(alumni => (
                                <div className="col-4 col-12-medium">
                                    <section className="highlight">
                                        <AlumniCard>
                                            <div className="ql-editor" dangerouslySetInnerHTML={{__html: alumni.contentHTML}} />
                                        </AlumniCard>
                                    </section>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>)}
        </div>

    )
}