import React, {useState, useEffect} from 'react';
import {
    Routes,
    Route,
    Link,
    Navigate,
    Outlet,
} from 'react-router-dom';
import {adminTokenCheck} from "../utils/api";


export default function ProtectedRoute(props) {
    const {redirectPath} = props

    const userID = localStorage.getItem('userID')
    const userToken = localStorage.getItem("loginToken")
    const [accessGranted, setAccessGranted] = useState()



    useEffect(() => {
        adminTokenCheck(userID, userToken).then(res => {
            if (res.data.result === "1") {
                setAccessGranted(true)
            } else {
                setAccessGranted(false)
            }
        })
    }, [])

    if (userToken === null || userID === null) {
        return <Navigate to={redirectPath} reload/>
    }

    if (accessGranted === undefined) {
        return null
    }
    return accessGranted === true ? <Outlet /> : <Navigate to={redirectPath} reload/>

}