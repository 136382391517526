import React, {useState, useEffect} from 'react';
import {getPDFFile} from "../../utils/api";
import {useParams} from "react-router";
import {PDFemb} from "./subComponents/CustomStyles";


export default function ShowcasePDFDownload() {
    const {PDF_id} = useParams()
    const [filePDF, setFilePDF] = useState();

    function displayPDF(base64String) {
        // Remove "data:application/pdf;base64," from the start of the string
        const cleanString = base64String.replace(/^data:.+;base64,/, '');

        // Convert base64 to raw binary data held in a string
        let byteCharacters;
        try {
            byteCharacters = atob(cleanString);
        } catch (error) {
            console.error("Invalid base64 string", error);
            return;
        }

        // Convert raw data to bytes
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Convert byte array to a Blob
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], {type: "application/pdf"});

        // Revoke the old URL
        URL.revokeObjectURL(filePDF)
        setFilePDF("")

        // Create URL from Blob
        let fileUrl = URL.createObjectURL(blob);
        setFilePDF(fileUrl)
    }

    useState(() => {
        getPDFFile(PDF_id).then(res => {
            displayPDF(res.data)
        })
    })

    document.body.style.overflow = "hidden"

    return (
        <div className="ShowcaseResearchDownload">
            {filePDF &&
                (<PDFemb src={filePDF} scrolling="no"/>)}
        </div>
    )
}