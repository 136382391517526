import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {putMessage} from "../../../utils/api";

const ClustrMap = styled.div`
  margin: 25px;
  align-content: center;
`

const ContactDiv = styled.div`
  padding: 0;
`


export default function ShowcaseFooter(props) {
    const {contentList} = props
    const [mail, setMail] = useState()
    const [social, setSocial] = useState()
    const [emailContact, setEmailContact] = useState()
    const [phone, setPhone] = useState()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")


    useEffect(() =>{
        if (contentList.length !== 0) {
            setMail(contentList.filter(o => o.contentID === "contact_01")[0])
            setSocial(contentList.filter(o => o.contentID === "contact_02")[0])
            setEmailContact(contentList.filter(o => o.contentID === "contact_03")[0])
            setPhone(contentList.filter(o => o.contentID === "contact_04")[0])
        }
    }, [contentList])

    function onInputName({target: {value}}) {
        setName(value)
    }

    function onInputEmail({target: {value}}) {
        setEmail(value)
    }

    function onInputMessage({target: {value}}) {
        setMessage(value)
    }

    function submitMessage() {
        if (name && email && message) {
            putMessage(name, email, message).then(res => {
                alert("Submission success!")
                window.location.reload()
            })
        } else {
            alert("Please enter name or email!")
        }
    }

    return (
        <div className="ShowcaseFooter" id="contact">
            <section id="footer" className="wrapper">
                <div className="title">Contact</div>
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-12-medium">
                            <section>
                                <Form onSubmit={submitMessage}>
                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Name"
                                            value={name}
                                            onChange={onInputName}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={onInputEmail}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="message">
                                        <Form.Control
                                            required
                                            type="text"
                                            as="textarea"
                                            rows={5}
                                            placeholder="Message"
                                            value={message}
                                            onChange={onInputMessage}
                                        />
                                    </Form.Group>
                                    <Button variant="submit" type="submit">Submit</Button>
                                </Form>
                            </section>

                        </div>
                        <div className="col-6 col-12-medium">

                            <section id="Contact" className="feature-list small">
                                <div className="row">
                                    {mail &&
                                        (
                                            <div className="col-6 col-12-small">
                                                <section>
                                                    <h3 className="icon solid fa-home"> Mailing Address</h3>
                                                    <ContactDiv className="ql-editor" dangerouslySetInnerHTML={{__html:mail.contentHTML}}/>
                                                </section>
                                            </div>
                                        )
                                    }
                                    {social &&
                                        (
                                            <div className="col-6 col-12-small">
                                                <section>
                                                    <h3 className="icon solid fa-comment"> Social</h3>
                                                    <ContactDiv className="ql-editor" dangerouslySetInnerHTML={{__html:social.contentHTML}}/>
                                                </section>
                                            </div>
                                        )
                                    }
                                    {emailContact &&
                                        (
                                            <div className="col-6 col-12-small">
                                                <section>
                                                    <h3 className="icon solid fa-envelope"> Email</h3>
                                                    <ContactDiv className="ql-editor" dangerouslySetInnerHTML={{__html:emailContact.contentHTML}}/>
                                                </section>
                                            </div>
                                        )
                                    }
                                    {phone &&
                                        (
                                            <div className="col-6 col-12-small">
                                                <section>
                                                    <h3 className="icon solid fa-phone"> Phone</h3>
                                                    <ContactDiv className="ql-editor" dangerouslySetInnerHTML={{__html:phone.contentHTML}}/>
                                                </section>
                                            </div>
                                        )
                                    }
                                </div>
                            </section>

                        </div>
                    </div>
                    <div id="copyright">
                        <ClustrMap>
                            <a href="https://clustrmaps.com/site/1bu06"  title="Visit tracker"><img src="//www.clustrmaps.com/map_v2.png?d=SYRzygw20kVwYYbfOw3aYjYpxNLsCHAj9n_mKxNhKKQ&cl=ffffff" /></a>
                        </ClustrMap>
                    </div>
                    <div id="copyright">
                        <ul>
                            <li>&copy; All rights reserved Zhiyu Quan 2023.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    )
}