import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from "react-router";
// import ".//assets/css/main.css";
// import ".//assets/css/modified.css";
import {getContentByClass, getContentClass} from "../../utils/api";
import ShowcaseHeader from "./subComponents/ShowcaseHeader";
import ShowcaseFooter from "./subComponents/ShowcaseFooter";
import ShowcaseAlumniListContainer from "./subComponents/ShowcaseAlumniListContainer";


export default function ShowcaseAlumniList() {
    const [contentList, setContentList] = useState([])
    const [alumniList, setAlumniList] = useState([])
    const [displayStudent, setDisplayStudent] = useState(false)

    useState(() => {
        getContentByClass("homePage").then(res => {
            setContentList(res.data)
        })
        getContentByClass("alumni").then(res => {
            setAlumniList(res.data)
        })
        getContentClass('alumni').then(res => {
            setDisplayStudent(res.data.displaySwitch)
        })
    })


    return (
        <div className="ShowcaseAlumniList">
            {contentList &&
            (<div id="page-wrapper">

                <ShowcaseHeader contentList={contentList} />

                {displayStudent &&
                (
                    <ShowcaseAlumniListContainer alumniList={alumniList} />
                )
                }


                <ShowcaseFooter contentList={contentList}/>
            </div>)}
        </div>
    )
}