import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from "react-router";
// import ".//assets/css/main.css";
// import ".//assets/css/modified.css";
import {
    getContentByClass,
    getContentClass, getHomePageContent, getResearchContent,
    getResearchPaper,
    getResearchPaperInfo,
    getViewCount,
    updateViewCount
} from "../../utils/api";
import ShowcaseHeader from "./subComponents/ShowcaseHeader";
import ShowcasePublications from "./subComponents/ShowcasePublications";
import ShowcaseResearchDetail from "./subComponents/ShowcaseResearchDetail";
import ShowcaseFooter from "./subComponents/ShowcaseFooter";


export default function ShowcaseResearch() {
    const location = useLocation()
    const {researchID} = useParams()
    const [viewCount, setViewCount] = useState(0)
    const [downloadCount, setDownloadCount] = useState(0)
    const [contentList, setContentList] = useState([])
    const [researchPaperDetail, serResearchPaperDetail] = useState()
    const [pdfFile, setPdfFile] = useState("")




    useState(() => {
        getContentByClass("homePage").then(res =>{
            setContentList(res.data)
        })
        getResearchPaperInfo(researchID).then(res => {
            setViewCount(res.data[0].view_count)
            setDownloadCount(res.data[0].download_count)
            // setPdfFile(res.data[0].pdf_file)
        })
    })


    return (
        <div className="ShowcaseResearch">
            {contentList &&
            (<div id="page-wrapper">

                <ShowcaseHeader contentList={contentList} />

                <ShowcaseResearchDetail researchID={researchID} viewCount={viewCount} downloadCount={downloadCount}/>

                <ShowcaseFooter contentList={contentList}/>
            </div>)}
        </div>
    )
}