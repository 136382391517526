import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import AdminNavBar from "./subComponents/AdminNavBar";
import {deleteMessage, getAllMessages} from "../../utils/api";
import Container from "react-bootstrap/Container";
import {MessageTable} from "./subComponents/CustomStyles";
import Button from "react-bootstrap/Button";

export default function AdminHomePage() {
    const [messageList, setMessageList] = useState([])

    useState(() => {
        getAllMessages().then(res => {
            console.log(res.data)
            setMessageList(res.data)
        })
    })

    function deleteCurrMessage(messageID) {
        deleteMessage(messageID).then(res => {
            window.location.reload()
        })
    }


    return (
        <div className="AdminHomePage">
            <AdminNavBar />
            <Container>
                <MessageTable striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>Message ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Message</th>
                            <th>Date</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {messageList &&
                            messageList.map(message => (
                                <tr key={message.messageID}>
                                    <th>{message.messageID}</th>
                                    <th>{message.name}</th>
                                    <th>{message.email}</th>
                                    <th>{message.message}</th>
                                    <th>{message.date}</th>
                                    <th><Button onClick={() => deleteCurrMessage(message.messageID)}>Delete</Button></th>
                                </tr>
                            ))
                        }
                    </tbody>
                </MessageTable>
            </Container>
        </div>
    )
}