import React from 'react';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Table from 'react-bootstrap/Table';
import {Card} from "react-bootstrap";


export const H5NotFound = styled.h5`
  text-align: center;
`

export const ContentEditingButtons = styled(Button)`
  margin: 5px;
`

export const ContentEditingCard = styled(Card)`
  margin: 20px;
  padding: 15px;

`

export const CitationButtons = styled(Button)`
  margin: 5px;
  width: 75px;
  height: 40px;
`

export const CitationRemoveButtons = styled(Button)`
  margin: 5px;
  //display: inline-flex;
  //align-content: end;
  //width: 20px;
  //min-width: 15px;
  height: 40px;
`



export const ContentPageContainer = styled(Container)`
  margin-top: 20px;      
`


export const PreviewPDFemb = styled.embed`
  min-width: 100%;
  min-height: 95vh;
`


export const MessageTable = styled(Table)`
  margin: 40px;
`