import React, {useState, useEffect} from 'react';
import  './quillContent.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ShowcaseBiography(props) {
    const {contentList} = props

    const [biographyTitle, setBiographyTitle] = useState('');
    const [biographyOverview, setBiographyOverview] = useState('');
    const [biographyInterest, setBiographyInterest] = useState('');
    const [biographyEducation, setBiographyEducation] = useState('');

    useEffect(() =>{
        if (contentList.length !== 0) {
            setBiographyTitle(contentList.find(o => o.contentID === "biography_title"))
            setBiographyOverview(contentList.find(o => o.contentID === "biography_overview"))
            setBiographyInterest(contentList.find(o => o.contentID === "biography_interest"))
            setBiographyEducation(contentList.find(o => o.contentID === "biography_education"))
        }
    }, [contentList])


    return (
        <div className="ShowcaseBiography">
            {(biographyTitle && biographyInterest && biographyEducation) &&
            (<section id="intro" className="wrapper style1">
                <div className="title">Biography</div>
                <div className="container">
                    <Row>
                        <div className="ql-editor" dangerouslySetInnerHTML={{__html:biographyTitle.contentHTML}}/>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <div className="ql-editor" dangerouslySetInnerHTML={{__html:biographyOverview.contentHTML}}/>
                        </Col>
                        <Col xs={4}>
                            <div className="ql-editor" dangerouslySetInnerHTML={{__html:biographyInterest.contentHTML}}/>
                        </Col>
                        <Col xs={4}>
                            <div className="ql-editor" dangerouslySetInnerHTML={{__html:biographyEducation.contentHTML}}/>
                        </Col>
                    </Row>
                </div>
            </section>)}
        </div>
    )
}