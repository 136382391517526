import React, {useState, useEffect} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import  './quillContent.css';


export default function ShowcaseExperience(props) {
    const {contentList} = props

    // const [experienceTitle, setExperienceTitle] = useState('')
    const [experienceList, setExperienceList] = useState([]);

    useEffect(() =>{
        if (contentList.length !== 0) {
            // setExperienceTitle(contentList.find(o => o.contentTitle === "experience_title"))
            setExperienceList(contentList.filter(o => o.contentTitle === "experience_content"))
            // console.log(contentList.filter(o => o.contentTitle === "experience_content"))
        }
    }, [contentList])


    return (
        <div className="ShowcaseExperience" id="experience">

            {(experienceList) &&
            (<section id="main" className="wrapper style2">
                <div className="title">Experience</div>
                <div className="container">
                    <section id="features">
                        {/*<header className="style1">*/}
                        {/*    <h2 dangerouslySetInnerHTML={{__html: experienceTitle.contentHTML}}/>*/}
                        {/*</header>*/}
                        <div className="feature-list">
                            <div className="row">
                                <Timeline position="alternate">
                                {experienceList.map(experience =>
                                    (

                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineDot />
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <div className="ql-editor" dangerouslySetInnerHTML={{__html:experience.contentHTML}}/>
                                                </TimelineContent>
                                            </TimelineItem>
                                    )
                                )}
                                </Timeline>
                            </div>
                        </div>
                        <ul className="actions special">
                        </ul>
                    </section>

                </div>
            </section>)}
        </div>
    )
}