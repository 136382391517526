import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router";
import {getCitation, getResearchContent, updateDownloadCount, updateViewCount} from "../../../utils/api";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import {Link} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styled from 'styled-components';

const ResearchLink = styled(Link)`
  margin-left: 25px;
`


export default function ShowcaseResearchDetail(props) {
    // const navigate = useNavigate()
    // const citeList = [{"name": "APA", "content": "123"},{"name": "Havard", "content": "ava"}]


    const {researchID, viewCount, downloadCount} = props


    const [researchDetail, setResearchDetail] = useState();
    const [copied , setCopied] = useState(false);
    const [citeModal, setCiteModal] = useState(false);
    const [citeIndex, setCiteIndex] = useState("");
    const [citeList, setCiteList] = useState([]);

    useState(() => {
        getCitation(researchID).then(res => {
            setCiteList(res.data)
            setCiteIndex(res.data[0].name)
        })
        getResearchContent(researchID).then(res => {
            setResearchDetail(res.data)
            if (res.data !== []) {
                updateViewCount(researchID).then(response => {
                })
            }
        })
    })

    function download() {
        updateDownloadCount(researchID).then(res => {
        })
    }

    const testCite = "cite here"


    return (
        <div className="ShowcaseResearchDetail">
            {researchDetail &&
            (<section id="main" className="wrapper style2">
                <div className="title">Research Paper Detail</div>
                <div className="container">
                    <div id="content">
                        <article className="box post">
                            <header className="ql-editor" dangerouslySetInnerHTML={{__html:researchDetail[0].contentHTML}}/>
                            <p className="ql-editor" dangerouslySetInnerHTML={{__html:researchDetail[1].contentHTML}}/>
                            <hr/>
                            <ResearchLink to={`/research/${researchID}/download`} target='_blank' onClick={download}>download</ResearchLink>
                            {
                                citeModal ?
                                    (
                                        <div
                                            className="modal show"
                                            style={{ display: 'block', position: 'initial' }}
                                        >
                                            <Modal.Dialog>
                                                <Modal.Header closeButton onClick={() => setCiteModal(false)}>
                                                    <Modal.Title>Cite</Modal.Title>
                                                </Modal.Header>

                                                <Nav defaultActiveKey="/home" as="ul">
                                                    {citeList.map(cite => (
                                                        <Nav.Item as="li">
                                                            <Nav.Link disabled={citeIndex === cite.name} onClick={() => setCiteIndex(cite.name)}>{cite.name}</Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                                </Nav>

                                                {citeList.map(cite => (
                                                    <>
                                                        {(citeIndex === cite.name) &&
                                                        (
                                                            <>
                                                                <Modal.Body>
                                                                    <p>{cite.content}</p>
                                                                </Modal.Body>
                                                                <Modal.Footer>
                                                                    <CopyToClipboard text={cite.content} onCopy={() => setCopied(true)}>
                                                                        <Button>Copy to Clipboard</Button>
                                                                    </CopyToClipboard>
                                                                </Modal.Footer>
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                ))}


                                            </Modal.Dialog>
                                        </div>
                                    ) : (
                                        <ResearchLink to={{}} onClick={() => setCiteModal(true)}>cite</ResearchLink>
                                    )
                            }
                            {/*<Button variant="link" onClick={toDownload}>download</Button>*/}
                            <p>&nbsp; &nbsp; view: {viewCount} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; download: {downloadCount}</p>



                        </article>
                    </div>
                </div>
            </section>)}
        </div>
    )
}