import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate, useParams} from "react-router";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import {
    deleteContentByID,
    deleteResearchPaper,
    getContentByClass,
    getContentByID, getContentClass,
    postNewContentClass
} from "../../utils/api";
import AdminNavBar from "./subComponents/AdminNavBar";
import {ContentEditingButtons, ContentPageContainer} from './subComponents/CustomStyles';
import 'react-quill/dist/quill.snow.css';
import EditingComponent from "./subComponents/EditingComponent";


export default function ContentPage() {
    const {contentClass} = useParams();
    const navigate = useNavigate();
    const [editing, setEditing] = useState(false);
    const [editingID, setEditingID] = useState('');
    const [contentList, setContentList] = useState([]);
    const [dataRaw, setDataRaw] = useState([]);
    const [displayName, setDisplayName] = useState("");
    const [displaySwitch, setDisplaySwitch] = useState(false);

    useState(() => {
        getContentByClass(contentClass).then(res => {
            setDataRaw(res.data)
            setContentList(res.data)
            setEditing(false)
            setEditingID('')
        })
        getContentClass(contentClass).then(res => {
            setDisplayName(res.data.displayName)
            setDisplaySwitch(res.data.displaySwitch)
        })
    })

    function editContent(contentID) {
        setEditingID(contentID)
        setEditing(true)
        //navigate(`/admin/content-editing/${contentID}`)
    }

    function deleteContent(contentID, contentClass) {
        deleteContentByID(contentID, contentClass).then(res => {
            window.location.reload()
        })
    }

    function newContent() {
        //navigate(`/admin/content-editing/new`)
        setEditingID("new")
        setEditing(true)
    }

    function completeEditing() {
        setEditingID('')
        setEditing(false)
        window.location.reload()
    }

    function switchDisplay() {
        postNewContentClass(contentClass, displayName, !displaySwitch).then(r => {
            window.location.reload()
        })
    }


    return (
        <div className="ContentPage">
            <AdminNavBar />
            <ContentPageContainer>
                <div>
                    <Button
                        onClick={switchDisplay}
                    >
                        {displaySwitch ?
                            (<div>Displayed</div>)
                            :
                            (<div>Hidden</div>)
                        }
                    </Button>
                </div>
                <br/>
                {contentList &&
                contentList.map(content =>
                    (
                        <div key={content.contentID}>
                            {
                                editing ?
                                    ((content.contentID === editingID)?
                                        (
                                            <div className="ContentEditing">
                                                <EditingComponent currentID={content.contentID} currentClass={contentClass} newContent={false} completeEditing={completeEditing}/>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="ContentDisplay">
                                                <h2>{content.contentTitle}</h2>
                                                <div className="ql-editor" dangerouslySetInnerHTML={{__html:content.contentHTML}} />
                                            </div>
                                        )
                                    )
                                    :
                                    (
                                        <div className="ContentDisplay">
                                            <h2>{content.contentTitle}</h2>
                                            <div className="ql-editor" dangerouslySetInnerHTML={{__html:content.contentHTML}} />
                                            <ContentEditingButtons variant="dark" onClick={() => editContent(content.contentID)}>Edit</ContentEditingButtons>
                                            <ContentEditingButtons variant="danger" onClick={() => deleteContent(content.contentID, content.contentClass)}>Delete</ContentEditingButtons>
                                        </div>
                                    )
                            }


                            <hr/>
                        </div>
                    )
                )

                }


                {
                    (editingID === "new") &&
                    (
                        <div className="ContentEditing">
                            <EditingComponent currentID={"new"} newContent={true} currentClass={contentClass} completeEditing={completeEditing}/>
                        </div>
                    )
                }
                {
                    !editing &&
                    (
                        <ContentEditingButtons onClick={newContent}>New Content</ContentEditingButtons>
                    )
                }
            </ContentPageContainer>
        </div>
    )
}