import React from 'react';
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";


export const AlumniCard = styled(Card)`
  min-height: 300px;
  min-width: 120px;
`

export const PDFemb = styled.embed`
  height: 100vh;
  width: 100vw;
`