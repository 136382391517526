import React, {useState, useEffect} from 'react';
import Carousel from 'react-bootstrap/Carousel';


export default function ShowcaseTalks(props) {
    const {contentList} = props
    const [picList, setPicList] = useState([]);
    const [talkList, setTalkList] = useState([]);
    const [idx, setIdx] = useState([]);

    useEffect(() =>{
        if (contentList.length !== 0) {
            setPicList(contentList.filter(o => o.contentTitle === "talk_pic").reverse())
            setTalkList(contentList.filter(o => o.contentTitle === "talk_title").reverse())
            let idxList = []
            for (let i = 0; i < contentList.filter(o => o.contentTitle === "talk_pic").reverse().length; i++) {
                idxList.push(i)
            }
            setIdx(idxList)

        }
    }, [contentList])


    return (
        <div className="ShowcaseTalks" id="talks">
            {talkList &&
            (<section id="main" className="wrapper style1">
                <div className="title">Recent & Upcoming Talks</div>
                <div className="container">

                    <section id="features">
                        <Carousel >
                            {idx.map(i =>
                                (
                                    <Carousel.Item key={i} interval={1500}>
                                        <div className="ql-editor" dangerouslySetInnerHTML={{__html:picList[i].contentHTML}}/>
                                        <Carousel.Caption>
                                            <div className="ql-editor" dangerouslySetInnerHTML={{__html:talkList[i].contentHTML}}/>
                                        </Carousel.Caption>

                                    </Carousel.Item>
                                )
                            )}
                        </Carousel>
                    </section>

                </div>
            </section>)}
        </div>
    )
}
