import React, {useState, useEffect} from 'react';
// import ".//assets/css/main.css";
// import ".//assets/css/modified.css";
import {getContentByClass, getContentClass, getResearchPaper} from "../../utils/api";
import ShowcaseHeader from "./subComponents/ShowcaseHeader";
import ShowcasePublications from "./subComponents/ShowcasePublications";
import ShowcaseResearchDetail from "./subComponents/ShowcaseResearchDetail";
import ShowcaseFooter from "./subComponents/ShowcaseFooter";
import ShowcaseResearchListContainer from "./subComponents/ShowcaseResearchListContainer";


export default function ShowcaseResearchList() {
    const [researchList, setResearchList] = useState([])
    const [contentList, setContentList] = useState([])
    const [displayResearch, setDisplayResearch] = useState(false)

    useState(() => {
        getContentByClass("homePage").then(res => {
            setContentList(res.data)
        })
        getContentByClass("research_overview").then(res => {
            setResearchList(res.data)
        })
        getContentClass('research_overview').then(res => {
            setDisplayResearch(res.data.displaySwitch)
        })
    })


    return (
        <div className="ShowcaseResearch">
            {contentList &&
            (<div id="page-wrapper">

                <ShowcaseHeader contentList={contentList} />

                {displayResearch &&
                    (<ShowcaseResearchListContainer researchList={researchList}/>)
                }


                <ShowcaseFooter contentList={contentList}/>
            </div>)}
        </div>
    )
}