import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {Card, Container} from "react-bootstrap"
import {
    getContentByID,
    getFile, getPDFcontent,
    getResearchPaperInfo,
    insertNewContent,
    uploadFile,
    uploadPaper,
    upsertContent
} from "../../../utils/api";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    H5NotFound,
    ContentEditingButtons,
    PreviewPDFemb,
    CitationButtons,
    CitationRemoveButtons,
    ContentEditingCard
} from './CustomStyles';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function EditingComponent(props) {
    const navigate = useNavigate();
    // const {contentIDOld} = useParams();
    const {currentID, completeEditing, newContent, currentClass} = props
    const [idNotFound, setIdNotFound] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [contentHTML, setContentHTML] = useState("");
    const [contentDelta, setContentDelta] = useState({});
    const [contentTitle, setContentTitle] = useState("");
    const [newContentID, setNewContentID] = useState("");
    const [researchFilePDF, setResearchFilePDF] = useState("");
    const [researchFilePDFurl, setResearchFilePDFurl] = useState("");
    const [citationList, setCitationList] = useState([{name: "", content: ""}]);

    function displayResearchPDF(base64String) {
        // Remove "data:application/pdf;base64," from the start of the string
        const cleanString = base64String.replace(/^data:.+;base64,/, '');

        // Convert base64 to raw binary data held in a string
        let byteCharacters;
        try {
            byteCharacters = atob(cleanString);
        } catch (error) {
            console.error("Invalid base64 string", error);
            return;
        }

        // Convert raw data to bytes
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Convert byte array to a Blob
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], {type: "application/pdf"});

        // Revoke the old URL
        URL.revokeObjectURL(researchFilePDFurl)
        setResearchFilePDFurl("")

        // Create URL from Blob
        let fileUrl = URL.createObjectURL(blob);
        setResearchFilePDFurl(fileUrl)
    }

    useState(() => {
        if (currentID === "new") {
            setNewContentID("")
            setContentTitle("")
            setLoaded(true)
        } else {
            setNewContentID(currentID)
            if (currentClass === "research_file") {
                getResearchPaperInfo(currentID).then(res => {
                    setResearchFilePDF(res.data[0].pdf_file)
                    displayResearchPDF(res.data[0].pdf_file)
                    setContentTitle(res.data[0].title)
                    setCitationList(res.data[0].citationList)
                    if (res.data[0].citationList === undefined) setCitationList([{name: "", content: ""}])
                })
            } else if (currentClass === "PDF_content") {
                getPDFcontent(currentID).then(res => {
                    setResearchFilePDF(res.data[0].pdf_file)
                    displayResearchPDF(res.data[0].pdf_file)
                    setContentTitle(res.data[0].contentTitle)
                })
            } else {
                getContentByID(currentID).then(res => {
                    setContentHTML(res.data[0].contentHTML)
                    setContentDelta(JSON.parse(res.data[0].contentDelta))
                    setContentTitle(res.data[0].contentTitle)
                    setLoaded(true)
                })
            }
        }
    })

    function pushContent() {
        if (newContentID === "" || contentTitle === "") {
            alert("Invalid content ID or title")
            return
        }
        if (currentClass === "PDF_content") {
            uploadFile(newContentID, researchFilePDF, contentTitle).then(res => {
                URL.revokeObjectURL(researchFilePDFurl)
                setResearchFilePDFurl("")
            })
        }

        if (currentClass === "research_file") {
            uploadPaper(newContentID, researchFilePDF, contentTitle, citationList).then(res => {
                URL.revokeObjectURL(researchFilePDF)
                setResearchFilePDF("")
            })
        }
        const contentDeltaString = JSON.stringify(contentDelta)
        if (newContent === true) {
            insertNewContent(newContentID, contentTitle, contentHTML, contentDeltaString, currentClass).then(res => {
                console.log(res)
                //navigate("/admin/content")
                completeEditing()
            })
        } else {
            upsertContent(currentID, newContentID, contentTitle, contentHTML, contentDeltaString, currentClass).then(res => {
                console.log(res)
                //navigate("/admin/content")
                completeEditing()
            })
        }
    }

    function cancelEditing() {
        URL.revokeObjectURL(researchFilePDF)
        setResearchFilePDF("")
        window.location.reload()
    }

    function onInputID({target: {value}}) {
        setNewContentID(value)
    }

    function onInputTitle({target: {value}}) {
        setContentTitle(value)
    }

    function onUploadResearchPDF({target: {files}}) {
        URL.revokeObjectURL(researchFilePDFurl)
        setResearchFilePDFurl("")
        setResearchFilePDFurl(URL.createObjectURL(files[0]))
        getBase64(files[0], (result) => {
            setResearchFilePDF(result)
        })
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    function onInputCitation (index, event) {
        console.log(event.target.value)
        let data = [...citationList]
        data[index][event.target.name] = event.target.value;
        setCitationList(data)
    }

    function addCitation() {
        let newCitation = {name: "", content: ""}
        setCitationList([...citationList, newCitation])
    }

    function removeCitation(index) {
        let data = [...citationList];
        data.splice(index, 1)
        setCitationList(data)
    }

    const modules = {
        toolbar: [
            [{header: '1'}, {header: '2'}, {font: [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'direction', 'align'],
            [{list: 'ordered'}, {list: 'bullet'},
                {indent: '-1'}, {indent: '+1'}],
            ['link', 'image', 'video', 'formula'],
            [{ 'color': [] }, { 'background': [] }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize', 'Toolbar'],
            displaySize: true
        }
    }

    const formats = [
        'header', 'font', 'size', 'color', 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'align'
    ]

    function changeContent(content, delta, source, editor) {
        setContentDelta(editor.getContents())
        setContentHTML(content)
    }

    return (
        <div className="EditingPage">
            {idNotFound ?
            (<div>
            <br/>
            <br/>
            <H5NotFound>
                Content not found
            </H5NotFound>
            </div>)
            :
            (<Container>
                <ContentEditingCard>
                    <Form>
                        <Form.Group className="mb-3" controlId="formContentID">
                            {(currentClass === "research_file") ?
                                (<Form.Label>Research Paper ID</Form.Label>)
                                :
                                (<Form.Label>Content ID</Form.Label>)
                            }
                            <Form.Control type="text"
                                          placeholder="Enter Content ID"
                                          value={newContentID}
                                          onChange={onInputID}
                            />
                            <Form.Text className="text-muted">
                                Content ID is the display sequence
                            </Form.Text>
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formContentTitle">
                            {(currentClass === "PDF_content") &&
                                (<Form.Label>PDF file title</Form.Label>)
                            }
                            {(currentClass === "research_detail") &&
                                (<Form.Label>Research Paper ID</Form.Label>)
                            }
                            {(currentClass === "research_file") &&
                                (<Form.Label>Research Paper Name</Form.Label>)
                            }
                            {(currentClass !== "research_file" && currentClass !== "research_detail" && currentClass !== "PDF_content") &&
                                (<Form.Label>Content Title</Form.Label>)
                            }
                            <Form.Control type="text"
                                          placeholder="Enter Content Title"
                                          value={contentTitle}
                                          onChange={onInputTitle}
                            />
                            {(currentClass === "research_file") &&
                            (
                                <Form.Text className="text-muted">
                                    Research paper name is the display name on the content editing page
                                </Form.Text>
                            )
                            }
                        </Form.Group>

                        <br/>

                        {(currentClass === "research_file") &&
                        (
                            <>
                                {citationList.map((citation, index)=> (
                                        <div key={index}>
                                            <Row>
                                                <Form.Group as={Col} controlId="formContentTitle">
                                                    <Form.Text>
                                                        Citation #{index + 1}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Row>

                                            <Form.Group controlId="formContentTitle">
                                                <Form.Label>Citation Type</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Enter Citation Type"
                                                    value={citation.name}
                                                    onChange={event => onInputCitation(index, event)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formContentTitle">
                                                <Form.Label>Citation Content</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    type="text"
                                                    name="content"
                                                    placeholder="Enter Citation Content"
                                                    value={citation.content}
                                                    onChange={event => onInputCitation(index, event)}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formContentTitle">
                                                <CitationRemoveButtons variant="danger" onClick={() => removeCitation(index)}>Remove Citation</CitationRemoveButtons>
                                            </Form.Group>
                                        </div>
                                    )
                                )}
                                <CitationButtons variant="primary" onClick={addCitation}>Add Citation</CitationButtons>
                            </>
                        )
                        }

                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                        {(currentClass === "research_file" || currentClass === "PDF_content") ?
                            (<Form.Group className="mb-3" controlId="formContentHTML">
                                {/*<input type="file" name="upload" accept="application/pdf" onChange={onUploadResearchPDF}/>*/}
                                <Form.Control type="file"
                                              accept="application/pdf"
                                              files={researchFilePDF}
                                              onChange={onUploadResearchPDF}
                                />
                                <hr/>
                                <hr/>
                                <PreviewPDFemb src={researchFilePDFurl} />
                            </Form.Group>)
                            :
                            (<Form.Group className="mb-3" controlId="formContentHTML">
                                <ReactQuill theme="snow"
                                            value={contentDelta}
                                            modules={modules}
                                            formats={formats}
                                            onChange={changeContent}>
                                </ReactQuill>
                                {/*<QuillEditor onEditingContent={onChangeContent} thisContentHTML={contentHTML} thisContentDelta={contentDelta} loaded={loaded}/>*/}
                            </Form.Group>)
                        }

                        <></>

                        <ContentEditingButtons variant="primary" onClick={pushContent}>
                            Submit
                        </ContentEditingButtons>
                        <ContentEditingButtons variant="dark" onClick={cancelEditing}>
                            Cancel
                        </ContentEditingButtons>
                    </Form>
                </ContentEditingCard>


            </Container>)
            }
        </div>
    )
}