import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//auth checker
import ProtectedRoute from "./components/ProtectedRoute";

//admin pages
import AdminHomePage from "./components/AdminPages/AdminHomePage";
import LoginPage from "./components/LoginPage";
import ContentPage from "./components/AdminPages/ContentPage";

//showcase pages
import ShowcaseHomepage from "./components/DisplayPages/ShowcaseHomepage";
import ShowcaseResearch from "./components/DisplayPages/ShowcaseResearch";

//quill setup
import {Quill} from "react-quill";
import ImageResize from "quill-image-resize-module-react";
import {useState} from "react";
import {adminTokenCheck} from "./utils/api";
import ShowcaseResearchList from "./components/DisplayPages/ShowcaseResearchList";
import ShowcaseAlumniList from "./components/DisplayPages/ShowcaseAlumniList";
import ShowcaseResearchDownload from "./components/DisplayPages/ShowcaseResearchDownload";
import ShowcaseTeaching from "./components/DisplayPages/ShowcaseTeaching";
import ShowcasePDFDownload from "./components/DisplayPages/ShowcasePDFDownload";
Quill.register('modules/imageResize', ImageResize);


function App() {



    return (
        <div className="App">
            <BrowserRouter>
            <Routes>
                {/*admin pages*/}
                <Route exact path='/admin' element={<ProtectedRoute redirectPath="/login"/>}>
                    <Route exact path='/admin' element={<AdminHomePage />} />
                    <Route path='/admin/content/:contentClass' element={<ContentPage/>} />
                </Route>
                <Route path='/login' element={<LoginPage/>} />


                {/*showcase pages*/}
                <Route exact path="/" element={<ShowcaseHomepage />} />
                <Route path="/:PDF_id/download" element={<ShowcasePDFDownload />} />
                <Route exact path="/research" element={<ShowcaseResearchList />} />
                <Route path="/research/:researchID" element={<ShowcaseResearch />} />
                <Route path="/research/:researchID/download" element={<ShowcaseResearchDownload />} />
                <Route path="/alumni" element={<ShowcaseAlumniList />} />
                <Route path="/teaching" element={<ShowcaseTeaching />} />

                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
